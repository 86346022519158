<template>
  <nav class="navbar navbar-expand-lg fixed-top navbar-light bg-white">
    <div class="container-fluid">
      <RouterLink class="navbar-brand" to="/">
        <img src="https://nettedimages.s3.ap-south-1.amazonaws.com/ivisarvr_logo_transparent.png" alt="IVIS ARVR" width="120">
      </RouterLink>
      <button 
        class="navbar-toggler" 
        type="button" 
        data-bs-toggle="collapse" 
        @click="isNavbarCollapsed = !isNavbarCollapsed"
        data-bs-target="#navbarNavAltMarkup" 
        aria-controls="navbarNavAltMarkup" 
        aria-expanded="false" 
        aria-label="Toggle navigation"
      >
      <span v-if="!isNavbarCollapsed">X</span>
        <span v-else class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup">
        <div class="navbar-nav">
          <RouterLink class="nav-link" to="/face-augmentation">Face Augmentation</RouterLink>
          <RouterLink class="nav-link" to="/ar-campus">AR Enabled Campus</RouterLink>
          <RouterLink class="nav-link" to="/object-placement">Object Placement</RouterLink>
          <RouterLink class="nav-link" to="/marked-ar">Marked AR</RouterLink>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Nav-bar",
  data() {
    return {
      isNavbarCollapsed: true
    };
  },
};
</script>

<style scoped>
.bg-transparent {
  background-color: transparent !important;
}
</style>
