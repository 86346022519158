<template>
  <div class="d-flex main-layout flex-column min-vh-100">
    <Navbar/>
    <div class="flex-grow-1">
      <RouterView class="router-view"/>
    </div>
    <Footer></Footer>
</div>
</template>

<script>
import Navbar from './components/Navbar.vue'
import Footer from './components/Footer.vue'

export default {
  components: {
    Navbar,
    Footer
    }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
/* Assuming you're using Flexbox for the main layout */
.main-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
router-view {
  flex: 1 0 auto;
}
.footer {
  margin-top: auto; /* This will push the footer to the bottom */
}

</style>