<template>
  <div class="container mt-5 pt-5">
    <h1>Facelandmarks for multiple use cases</h1>
    <div id="liveView" class="videoView my-5">
      <div style="position: relative;">
        <video ref="webcam" autoplay playsinline></video>
        <canvas ref="outputCanvas" width="640" height="320" style="position: absolute; left: 0px; top: 0px;"></canvas>
      </div>
    </div>
    <button @click="startDemo" class="demo-button">Click here for demo</button>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { FaceLandmarker, FilesetResolver, DrawingUtils } from "@mediapipe/tasks-vision";

export default {
  name: "FaceLandmarks",
  setup() {
    const webcam = ref(null);
    const outputCanvas = ref(null);
    const faceLandmarker = ref(null);
    const isInitialized = ref(false);
    const isDemoStarted = ref(false);
    const videoWidth = 640;
    const videoHeight = 320;

    onMounted(async () => {
      await setupFaceLandmarker();
    });

    async function setupFaceLandmarker() {
      const filesetResolver = await FilesetResolver.forVisionTasks(
        "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.3/wasm"
      );
      faceLandmarker.value = await FaceLandmarker.createFromOptions(filesetResolver, {
        runningMode: "VIDEO",
        outputFaceBlendshapes: true,
        numFaces: 1,
        baseOptions: {
          modelAssetPath: 'https://storage.googleapis.com/mediapipe-models/face_landmarker/face_landmarker/float16/1/face_landmarker.task',
          delegate: "GPU"
        },
      });
      isInitialized.value = true;
    }

    async function enableCam() {
      const constraints = {
        video: {
          width: videoWidth,
          height: videoHeight
        }
      };
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      webcam.value.srcObject = stream;
      await webcam.value.play();
      predictWebcam();
    }

    async function predictWebcam() {
      if (!faceLandmarker.value || !isInitialized.value || !isDemoStarted.value) return;

      if (webcam.value.readyState === HTMLMediaElement.HAVE_ENOUGH_DATA) {
        const results = await faceLandmarker.value.detectForVideo(webcam.value);
        const canvasCtx = outputCanvas.value.getContext("2d");
        const drawingUtils = new DrawingUtils(canvasCtx);
        canvasCtx.clearRect(0, 0, videoWidth, videoHeight);
        for (const landmarks of results.faceLandmarks) {
        console.log(landmarks);
      drawingUtils.drawConnectors(
        landmarks,
        FaceLandmarker.FACE_LANDMARKS_TESSELATION,
        { color: "#C0C0C070", lineWidth: 1 }
      );
      drawingUtils.drawConnectors(
        landmarks,
        FaceLandmarker.FACE_LANDMARKS_RIGHT_EYE,
        { color: "#FF3030" }
      );
      drawingUtils.drawConnectors(
        landmarks,
        FaceLandmarker.FACE_LANDMARKS_RIGHT_EYEBROW,
        { color: "#FF3030" }
      );
      drawingUtils.drawConnectors(
        landmarks,
        FaceLandmarker.FACE_LANDMARKS_LEFT_EYE,
        { color: "#30FF30" }
      );
      drawingUtils.drawConnectors(
        landmarks,
        FaceLandmarker.FACE_LANDMARKS_LEFT_EYEBROW,
        { color: "#30FF30" }
      );
      drawingUtils.drawConnectors(
        landmarks,
        FaceLandmarker.FACE_LANDMARKS_FACE_OVAL,
        { color: "#E0E0E0" }
      );
      drawingUtils.drawConnectors(
        landmarks,
        FaceLandmarker.FACE_LANDMARKS_LIPS,
        { color: "#E0E0E0" }
      );
      drawingUtils.drawConnectors(
        landmarks,
        FaceLandmarker.FACE_LANDMARKS_RIGHT_IRIS,
        { color: "#FF3030" }
      );
      drawingUtils.drawConnectors(
        landmarks,
        FaceLandmarker.FACE_LANDMARKS_LEFT_IRIS,
        { color: "#30FF30" }
      );
    }
  }
     await window.requestAnimationFrame(predictWebcam);
    }

    function startDemo() {
      isDemoStarted.value = true;
      enableCam();
    }

    return {
      webcam,
      outputCanvas,
      startDemo
    };
  }
};
</script>
<style scoped>
/* Add any required styles here */

.demo-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: #007f8b;
  color: #f1f3f4;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.demo-button:hover {
  background-color: #005f6b;
}

</style>

