<template>
  <div class="ar-container">
    <div class="header">
      <h1>AR Experience</h1>
      <p>Point your camera at the 'IVIS ARVR' marker to see the AR content.</p>
    </div>
    <!-- A-Frame Scene -->
    <!-- Note that the 'embedded' attribute makes the scene fit within the div rather than take up the whole page -->
    <a-scene embedded arjs='sourceType: webcam; debugUIEnabled: false;' class="ar-scene">
          <a-marker preset="hiro">
        <a-box position="0 0.5 0" material="color: yellow;"></a-box>
      </a-marker>
      <a-entity camera></a-entity>
    </a-scene>
  </div>
</template>

<script>
export default {
  name: 'ARView',
  mounted() {
//    this.setupWebcam();
  },
  methods: {
    setupWebcam() {
      // Access the canvas element
      const canvas = this.$refs.canvas;
      const context = canvas.getContext('2d');
      
      // Set up the webcam stream
      navigator.mediaDevices.getUserMedia({ video: true })
        .then((stream) => {
          // Set the source of the video to be the webcam stream
          const video = document.createElement('video');
          video.srcObject = stream;
          video.play();
          
          // Draw the video onto the canvas
          const draw = () => {
            if (video.readyState === video.HAVE_ENOUGH_DATA) {
              canvas.width = video.videoWidth;
              canvas.height = video.videoHeight;
              context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
            }
            requestAnimationFrame(draw);
          };
          
          draw();
        })
        .catch((error) => {
          console.error('Error accessing the webcam', error);
        });
    }
  },
  
};
</script>


<style scoped>
.ar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header {
  text-align: center;
  margin-bottom: 20px;
}

.ar-scene {
  width: 640px !important; /* Set the width of the AR scene */
  height: 480px !important; /* Set the height of the AR scene */
  display: block !important;
}

/* You may need to override some default A-Frame styles */
.a-canvas {
  width: 640px !important; /* Set the width of the canvas drawn by A-Frame */
  height: 480px !important; /* Set the height of the canvas */
}

/* If you are using a specific class or ID for your canvas, you can target it directly */
#ar-canvas, .a-canvas {
  width: 640px !important;
  height: 480px !important;
}
</style>

