<template>
  
  <footer class="footer mt-auto py-3 bg-dark">  
    <div class="container">
      <div class="row">
        <div class="col-md-4 mb-3 mb-md-0 text-md-start text-start">
          <img src="https://nettedimages.s3.ap-south-1.amazonaws.com/ivisarvr_logo_transparent.png" alt="IVIS ARVR" class="footer-logo">
          <span class="text-white d-block mt-2 ">by IVIS LABS Pvt. Ltd. © 2023</span>
        </div>
        <div class="col-md-8 text-md-end text-start content-white">
          <div class="contact-details">
            <span class="text-white d-block mb-2">
              <i class="fas fa-envelope"></i> email: <a href="mailto:info@ivislabs.com">info@ivislabs.com</a>
            </span>
            <span class="text-white d-block mb-2">
              <i class="fas fa-phone-alt"></i> mobile: +91 6364411444
            </span>
            <span class="text-white d-block">
              <i class="fas fa-globe"></i> <a href="https://ivislabs.com" target="_blank">ivislabs.com</a>
            </span>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: 'Foo-ter',
}
</script>

<style scoped>
.custom-footer {
  padding: 20px 0;
  color: #333; /* Adjusted to a darker color for better contrast */
}

.footer-logo {
  width: 100%;
  max-width: 150px;
  margin-bottom: 10px;
}

.contact-details span {
  margin: 0 10px;
}

.contact-details a {
  color: #ffffff;
  text-decoration: none;
}

.contact-details a:hover {
  text-decoration: underline;
}
</style>
