<template>
    <div class="container my-1 py-5">
      <div class="card my-5 py-5" :style="{ backgroundColor: beigeBackground ? 'beige' : '' }">
        <h1>Augmented Reality enabled Campus</h1>
        <div class="container mx-5 px-5">
          <button v-if="showARButton" @click="startAR" class="btn btn-primary">Start AR Experience</button>
        </div>    
        <div class="row">
          <div class="col-md-4 d-flex align-items-center justify-content-center">
                <div v-if="showCanvas" class="btn-group-vertical">
                  <button
                    v-for="key in filteredMessagesKeys"
                    :key="key"
                    class="btn btn-secondary"
                    @click="selectMessage(key)"
                  >
                    {{ key }}
                  </button>
                </div>
          </div>
          <div class="col-md-8">
            <canvas v-if="showCanvas" ref="canvas"></canvas>
          </div>
        </div>
        <div v-if="showContactUs">      <p>Ready to make your place AR enabled? Contact us!</p>
          <a href="whatsapp://send?text=Hello!" target="_blank">
            <font-awesome-icon :icon="['fab', 'whatsapp']" />
          </a>
        </div>
    </div>
    <div class="row">
      <h1>Ready for a digital notice board?</h1>
      <div class="card">
        <img src="https://nettedimages.s3.ap-south-1.amazonaws.com/AR_CAMPUS2.png"/>
      </div>

    </div>

</div>
</template>

  
  <script>
  import locationsData from '@/assets/LocationAR.json';

  export default {
    data() {
      return {
        locations: locationsData,
        showCanvas: false,
        showARButton: true,
        mediaURLs: {},
        currentKey: 'Welcome Message', // Default key
        mediaType: '',
        message: '',
        beigeBackground: false,
        showContactUs: false,
      };
    },
    computed: {
      filteredMessagesKeys() {
        return Object.keys(this.mediaURLs).filter(key => key !== 'Welcome Message');
      }
    },
    methods: {
      playTTS(message) {
        // Cancel the current speech and clear the queue
        window.speechSynthesis.cancel();
        
        // Start the new speech utterance
        const speech = new SpeechSynthesisUtterance(message);
        window.speechSynthesis.speak(speech);
      },
      selectMessage(key) {
        const selectedMedia = this.mediaURLs[key];
        this.currentKey = key;
        this.message = selectedMedia.message;
        this.mediaURL = selectedMedia.url;
        this.mediaType = selectedMedia.type;
        this.renderMedia();
        this.playTTS(this.message);
      },
      renderMedia() {
        const canvas = this.$refs.canvas;
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the canvas for new media

        if (this.mediaType === 'image') {
          const img = new Image();
          img.onload = () => {
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          };
          img.src = this.mediaURL;
          } else if (this.mediaType === 'video') {
            const video = document.createElement('video');
            video.src = this.mediaURL[0];
            video.onloadedmetadata = function() {
              video.play();
              function drawVideoFrame() {
                ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
                requestAnimationFrame(drawVideoFrame);
              }
              drawVideoFrame();
            };
            } else if (this.mediaType === 'carousel') {
                let currentImageIndex = 0;
                const img = new Image();

                // Function to load the next image
                const loadNextImage = () => {
                  currentImageIndex = (currentImageIndex + 1) % this.mediaURL.length;
                  img.src = this.mediaURL[currentImageIndex];
                };

                // Set the onload function to draw the image and then set a timeout to load the next image
                img.onload = () => {
                  ctx.clearRect(0, 0, canvas.width, canvas.height);
                  ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                  // Set a timeout to wait 3 seconds before loading the next image
                  setTimeout(loadNextImage, 3000);
                };

                // Start the carousel by loading the first image
                loadNextImage();
              }


      },

      async startAR() {
        this.showARButton = false;
        this.showCanvas = true;
        this.beigeBackground = true; // Set the background to beige when AR starts

        console.log(this.locations);
        let locationFound = false;
        navigator.geolocation.getCurrentPosition((position) => {
          this.locations.forEach(location => {
            const distance = this.calculateDistance(location, position.coords);
            if (distance < location.radius) {
              locationFound = true;
              this.mediaURLs = location.mediaURLs;
                    // Assuming 'Welcome Message' is the key for the default message
              this.selectMessage('Welcome Message');
              this.playTTS(this.mediaURLs['Welcome Message'].message); // Play the default message
            }
          });
          if (!locationFound) {
            this.showContactUs = true; // Show the contact message if no location is found
          }
        });
      },
      calculateDistance(target, current) {
        const R = 6371e3; // Earth radius in meters
        const lat1 = this.toRadians(current.latitude);
        const lat2 = this.toRadians(target.latitude);
        const deltaLat = this.toRadians(target.latitude - current.latitude);
        const deltaLon = this.toRadians(target.longitude - current.longitude);
  
        const a = Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
                  Math.cos(lat1) * Math.cos(lat2) *
                  Math.sin(deltaLon / 2) * Math.sin(deltaLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  
        return R * c; // Distance in meters
      },
      toRadians(degrees) {
        return degrees * (Math.PI / 180);
      }
    }
  };
  </script>
  <style>
  .showContactUs img {
    width: 50px; /* or any size you prefer */
    height: auto;
  }
  .btn-group-vertical .btn {
    margin-bottom: 10px; /* Adjust the space as needed */
  }
  canvas {
    width: 100%; /* Full width on mobile devices */
    height: auto; /* Maintain aspect ratio */
    max-width: 640px; /* Maximum width on desktop */
    max-height: 320px; /* Maximum height on desktop */
  }

  @media (min-width: 768px) { /* Adjust breakpoint as needed for desktop */
    canvas {
      width: 640px;
      height: 320px;
    }
  }
  </style>