<template>
  <div class="container-fluid mt-5 px-3 pt-1 bg-light">
    <div class="row align-items-center">
      <div class="col-md-4 my-3 py-1">
        <img class="portrait-image" :src="portraitUrl" alt="Portrait">
      </div>
      <div class="col-md-8">
        <h1 class="display-4 text-end">IVIS ARVR</h1>
        <p class="lead text-end">Augmented Reality and Virtual Reality Solutions</p>
        <p class="typewriter text-end">Transforming Your World, One Layer at a Time</p>
        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Ho-me',
  data() {
    return {
      portraitUrl: 'https://nettedimages.s3.ap-south-1.amazonaws.com/IVISARVRHOME.jpg'
    };
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');

body {
  font-family: 'Open Sans', sans-serif;
  padding-top: 200px; /* Adjust based on the actual height of your navbar */
}

.typewriter {
  width: 100%;
  white-space: pre-wrap;
  overflow: hidden;
  border-right: .15em solid #007BFF; /* Adjusted color to Bootstrap primary blue */
  animation: typing 3.5s steps(40, end), blink-caret .75s step-end infinite;
}
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: #007BFF }
}

.portrait-image {
  object-fit: contain;
  max-height: 75vh;
  width: auto;
  border-radius: 100px; /* Adjusted for more pronounced curved edges */
  overflow: visible;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}


.portrait-image:hover {
  transform: scale(1.05); /* subtle zoom on hover */
}

@media (max-width: 992px) {
  .portrait-image {
    max-height: 60vh; /* Smaller max height for smaller screens */
  }
  body {
    padding-top: 50px; /* Reduced padding for smaller screens */
  }
}
</style>
