<template>
  <div class="container mt-5 pt-5">
    <div ref="canvasContainer" class="canvas-container"></div>
    <!-- Placeholder for 3D object controls, to be implemented -->
  </div>
</template>
<script setup>
import { ref, onMounted, onUnmounted, reactive } from 'vue';
import * as THREE from 'three';
const state = reactive({
  camera: null,
  scene: null,
  renderer: null,
  videoTexture: null,
  objects: [], // This will hold our 3D objects
  selectedObject: null, // The object currently selected by the user
});
const initThreeJs = () => {
  // Scene
  state.scene = new THREE.Scene();

  // Camera
  const aspectRatio = window.innerWidth / window.innerHeight;
  state.camera = new THREE.PerspectiveCamera(75, aspectRatio, 0.1, 1000);
  state.camera.position.z = 5;

  // Renderer
  state.renderer = new THREE.WebGLRenderer();
  state.renderer.setSize(window.innerWidth, window.innerHeight);
  canvasContainer.value.appendChild(state.renderer.domElement);

  // Video texture
  const video = document.createElement('video');
  if (navigator.mediaDevices.getUserMedia) {
    navigator.mediaDevices.getUserMedia({ video: true }).then((stream) => {
      video.srcObject = stream;
      video.play();
      state.videoTexture = new THREE.VideoTexture(video);
      // Set up the rest of your scene, including adding the video to a plane
      // and adding any initial objects to state.objects
    });
  }
  video.addEventListener('play', () => {
    const videoTexture = new THREE.VideoTexture(video);
    const videoMaterial = new THREE.MeshBasicMaterial({ map: videoTexture });
    const videoGeometry = new THREE.PlaneGeometry(5, 5); // Example size, adjust as needed
    const videoMesh = new THREE.Mesh(videoGeometry, videoMaterial);
    videoMesh.position.set(0, 0, 0); // Position it in front of the camera
    state.scene.add(videoMesh);

    canvasContainer.value.appendChild(state.renderer.domElement);
    animate();
  });  
};
/*const selectObject = (object) => {
  state.selectedObject = object;
  // Additional logic for handling object selection
};

const placeObject = (x, y) => {
  if (!state.selectedObject) return;

  // Convert (x, y) screen coordinates to world coordinates and place the object
  // You will need to calculate the correct position based on the camera and scene setup
};
const onCanvasClick = (event) => {
  // Determine the mouse position and call placeObject with the correct coordinates
};*/

// Add this event listener in the onMounted hook and remove it in onUnmounted
const canvasContainer = ref(null);

onMounted(() => {
  initThreeJs();
  // Add other initializations and event listeners as needed
});

onUnmounted(() => {
  // Clean up: dispose of Three.js objects and stop the webcam stream
});

const animate = () => {
  requestAnimationFrame(animate);

  // Update objects or animations in your scene
  state.renderer.render(state.scene, state.camera);
};

onMounted(() => {
  animate();
});

</script>
<style scoped>
.container {
  /* Styles for the container */
}
.canvas-container {
  /* Styles for the Three.js canvas */
}
</style>
